import { errorStatusMessage } from './errorStatusMessage'

export const locale = {
  errorMessages: errorStatusMessage,
  cooperate: 'Cooperate',
  bestb2b: 'Best b2b',
  betsText: 'Provider of fast games for the gambling business',
  casinoGames: 'Casino Games',

  // About us
  aboutUs: 'About us',
  aboutUsText1: `We are a team with extensive experience in the gambling business. We constantly monitor the market and create popular games. We also look to the future and design games that will be popular soon`,
  aboutUsText2: `Next generation smart games are gaining popularity in the online casino world, and we are among the pioneers with the best value proposition on the market.`,
  aboutUsText3: `Gaming systems developed`,
  aboutUsText4: `Partner companies around the world`,
  aboutUsText5: `People play our games`,

  //Let's try
  letsTry: 'Let\'s try',

  // Lets talk
  letsTalk: `Let’s talk!`,
  letsTalkText1: 'Contact us if you want to cooperate with us',

  // Our games
  ourGames: 'Our games',
  ourGamesDescription:'Fiable Games focuses on continuously improving the quantity and quality of games. That is why more and more players are playing our games, and the profits of our partners are growing steadily.',
  ourGameCommonText: 'Diverse and rich experience the existing structure of the organization requires us to analyze ',
  ourGameHiloText: 'One of the most popular card games in the world',
  ourGameFirecrashText: 'Our own crash game, one of the most popular casino games of 2023 year',
  ourGameSpin2WinText: 'Modern roulette game with two jackpots and hot numbers',
  ourGamePenaltyText: 'The aim of the game is to correctly predict which part of the goal the ball will hit',
  ourGameGlassBridgeText: ' The task of the players is to cross the bridge in turn, stepping only on the tempered glass tiles',
  ourGameSquidText: 'New design of our Crash based on the Squid Game',
  ourGameCookiesText: 'One of squid games. Guess where the whole cookies are',
  moreDetails: 'More details',
  ourGamePlinkoText: 'Plinko is a classic game of chance where the player drops a ball in a multi-row pin pyramid, with the ball bouncing between pins in a random route until it reaches a destination at the bottom of the pyramid.',
  ourGameKenoText: 'Keno is a lotto-style game very similar to Bingo. It is a wonderful game for anyone who likes to try luck at guessing numbers. Keno is probably the simplest game in the casino.',
  ourGameDinoText: 'Here we have a running Dino. Multiple players place bets and try to collect money before the asteroid falls on Dino.',
  ourGameDiceText: 'Determine your chance of winning and choose what the final number will be! A simple game for the lucky ones!',
  ourGameCoinflipText: 'This game works as heads or tails generator and allows you to flip a coin instantly! Make a decision fast! Just flip a coin!',

  // New game
  newGameLudoText:
    'An excellent game, with very simple rules, but to win in which you need to have strategic thinking and good luck',
  soon: 'Soon',
  newGameFirstTitle: 'New game',
  newGameSecondTitle: 'Announcement',
  newGameDesc:
    'Diverse and rich experience the existing structure of the organization requires us to analyze new proposals. Likewise, the scope and place of staff training require us to analyze further directions of development.',

  // Bespoke
  bespokeByttonText: 'Contact us if you want to cooperate with us',
  bespokeFirstTitle: 'Bespoke',
  bespokeSecondTitle: 'Gaming',
  bespokeSubTitle: 'Do you have an idea for your own game?',
  bespokeDesk: `But don't you have your own developers and designers?  Fiable Games will help you with this. You can order the development of the game from us. We have extensive experience in the development and gambling business, have launched more than 50 different projects in the field of gambling.`,

  //Our Partners
  ourPartners: 'Our Partners',
  ourPartnersDescription: 'We work both with large companies from the iGaming industry (aggregators and platform providers) and with casino owners (operators). Partnership with FiableGames is always a Win-Win game.',

  // Our Hightlights
  OHFirstTitle: 'Our',
  OHSecondTitle: 'Hightlights',
  OHSubTitle: 'Here are some reasons to work with us',
  OHFirstItemTitle: 'Perfect adaptation',
  OHFirstItemDesc: 'Works great on any screen and any device. Ideal for both desktop and on a mobile phone.',
  OHSecindItemTitle: 'Easy integration',
  OHSecondItemDesc:
    'Detailed documentation, integration with a frame, helping you with integration. We do everything to make it easier for you to use our games',
  OHThirdItemTitle: 'Regular updates',
  OHThirdItemDesc: 'We monitor the market, add and improve what will increase the profits of our partners',

  // Side menu
  sideMenuItems: {
    main: 'Main',
    aboutUs: 'About us',
    ourGames: 'Our games',
    newGame: 'New game',
    bespokeGaming: 'Bespoke gaming',
    ourPartners: 'Our Partners',
    highlights: 'Highlights',
    contuctUs: 'Contact us',
    letsTryMenu: "Let's try",
  },

  // Form
  contact: 'Contact',
  contactText: 'Left your contacts and we will answer you within 24 hours',
  sendRequest: 'Send request',
  sendEmailSuccess: email =>
    `Your application has been successfully accepted, soon we will reply to you by email ${email}`,
  sendEmailFail:
    'An error occurred while placing the application. Check if the mail you left is correct or try again later',
}
