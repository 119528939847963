import React from 'react'
import block from 'bem-cn'
import { Link } from 'react-router-dom'

import buttonArrow from './img/button-arrow.svg'

import './Button.scss'

const Button = ({
  callBack,
  text = '',
  type = 'button',
  color = 'transparent',
  size = 'default',
  isDisabled,
  isUppercase = false,
  isBold = false,
  fontSize = 'normal',
  isVertical = false,
  withArrow = false,
  link,
}) => {
  const b = block('button')

  const button = (
    <button
      type={type}
      className={b(
        { color },
        { size: link ? 'container' : size },
        { isUppercase, withArrow, isBold, fontSize, isVertical, isActive: isDisabled === false },
      )}
      onClick={callBack}
      disabled={isDisabled}>
      {isVertical ? text.split('').join(' ') : text}
      {withArrow && <img src={buttonArrow} className={b('arrow').toString()} alt='buttonArrow' />}
    </button>
  )

  return link ? (
    <Link to={link} className={b('layout', { size })}>
      {button}
    </Link>
  ) : (
    button
  )
}

export default Button
