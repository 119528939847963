import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { actions } from './logic';

import classes from './Demo.module.scss'

const platform = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? 'mobile' : 'desktop';

const Demo = () => {
  const dispatch = useDispatch();
  const { activeGame, token } = useSelector(state => state.demo);
  const { lang } = useSelector(state => state.locale)

  useEffect(() => {
    dispatch(actions.startActiveGame());
  }, [activeGame])

  const closeGame = useCallback(() => {
    dispatch(actions.setActiveGame(null))
  }, [dispatch])

  return platform === 'desktop' ? <div className={classes.DemoContainer} onClick={closeGame}>
      <iframe className={classes.DemoFrame} title="demo" src={`https://${activeGame}.games.fiable.games/?token=${token}&lang=${lang}&homeUrl=https://fiablegames.com`} alt='demo' />
    </div> : null;
}

export default Demo;