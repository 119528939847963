import React from 'react'
import { useSelector } from 'react-redux'
import classes from './OurHighlights.module.scss'

import first from '../img/OH-first.svg'
import second from '../img/OH-second.svg'
import third from '../img/OH-third.svg'

const OurHighlights = () => {
  const locale = useSelector(state => state.locale.locale)

  return (
    <article data-nav-id='highlights' className={classes.OurHighlightsPage}>
      <div className={classes.TextBlock}>
        <div className={classes.Plus}>+</div>
        <div className={classes.TitleContainer}>
          <h1 className={classes.Title}>{locale.OHFirstTitle}</h1>
          <h1 className={classes.Title}>{locale.OHSecondTitle}</h1>
        </div>
        <div className={classes.SubTitle}>{locale.OHSubTitle}</div>
      </div>
      <div className={classes.ItemsBlock}>
        <div className={classes.Item}>
          <img className={classes.ItemImage} src={first} alt='first' loading="lazy" />
          <div className={classes.ItemText}>
            <div className={classes.ItemTitle}>{locale.OHFirstItemTitle}</div>
            <div className={classes.ItemDesc}>{locale.OHFirstItemDesc}</div>
          </div>
        </div>
        <div className={classes.Item}>
          <img className={classes.ItemImage} src={second} alt='second' loading="lazy" />
          <div className={classes.ItemText}>
            <div className={classes.ItemTitle}>{locale.OHSecindItemTitle}</div>
            <div className={classes.ItemDesc}>{locale.OHSecondItemDesc}</div>
          </div>
        </div>
        <div className={classes.Item}>
          <img className={classes.ItemImage} src={third} alt='third' loading="lazy" />
          <div className={classes.ItemText}>
            <div className={classes.ItemTitle}>{locale.OHThirdItemTitle}</div>
            <div className={classes.ItemDesc}>{locale.OHThirdItemDesc}</div>
          </div>
        </div>
      </div>
    </article>
  )
}

export default OurHighlights
