import digitain from '../../img/Digitain.svg'
import evenbet from '../../img/Evenbet.svg'
import betronic from '../../img/Betronic.svg'
import gamingsoft from '../../img/Gamingsoft.svg'
import mondogaming from '../../img/Mondogaming.svg'
import greatstar from '../../img/Greatstar.svg'
import betdevel from '../../img/Betdevel.svg'
import easygames from '../../img/Easygames.svg'
import dreamsbet from '../../img/Dreamsbet.svg'
import eljam3ia from '../../img/Eljam3ia.svg'

const partners = [
  {
    icon: digitain,
    name: 'Digitain',
  },
  {
    icon: evenbet,
    name: 'Evenbet Gaming',
  },
  {
    icon: betronic,
    name: 'Betronic',
  },
  {
    icon: gamingsoft,
    name: 'GamingSoft',
  },
  {
    icon: mondogaming,
    name: 'Mondo Gaming',
  },
  {
    icon: greatstar,
    name: 'Greatstar',
  },
  {
    icon: betdevel,
    name: 'Betdevel',
  },
  {
    icon: easygames,
    name: 'Easygames',
  },
  {
    icon: dreamsbet,
    name: 'Dreamsbet',
  },
  {
    icon: eljam3ia,
    name: 'Eljam3ia',
  },
]

export { partners };