import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Lines } from 'react-preloaders'

import Notify from 'features/Notify/view'
import Form from 'features/Form'
import { Best, AboutUs, LetsTalk, OurGames, NewGame, Bespoke, OurHighlights, LetsTry, OurPartners } from 'components/Pages'
import SideMenu from 'components/SideMenu'
import { Demo } from 'features/Demo';
import ContactForm from 'components/ContactForm';

import './App.scss'
import classes from './App.module.scss'

const platform = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? 'mobile' : 'desktop';

const App = () => {
  const [isOpenSideMenu, setOpenSideMenu] = useState(false)
  const activeGame = useSelector(state => state.demo.activeGame)

  const switchIsOpenSideMenu = useCallback(() => {
    setOpenSideMenu(!isOpenSideMenu)
  }, [isOpenSideMenu])

  const [isOpenForm, setOpenForm] = useState(false)

  const switchIsOpenForm = useCallback(() => {
    setOpenForm(!isOpenForm)
  }, [isOpenForm])

  return (
    <React.Fragment>
      <Lines color="#FF8D00" background="#13171D" />
      <main className={classes.AppMain}>
        <Form isOpen={isOpenForm} switchIsOpenForm={switchIsOpenForm} />
        {activeGame && <Demo />}
        <Notify />
        <SideMenu
          isOpen={isOpenSideMenu}
          switchIsOpenSideMenu={switchIsOpenSideMenu}
          switchIsOpenForm={switchIsOpenForm}
        />
        <div className={classes.Section}>
          <Best switchIsOpenSideMenu={switchIsOpenSideMenu} switchIsOpenForm={switchIsOpenForm} />
        </div>
        <div className={classes.Section}>
          <AboutUs />
        </div>
        <div className={classes.Section}>
          <LetsTry />
        </div>
        <div className={classes.Section}>
          <OurGames switchIsOpenForm={switchIsOpenForm} />
        </div>
        <div className={classes.Section}>
          <NewGame switchIsOpenForm={switchIsOpenForm} />
        </div>
        <div className={classes.Section}>
          <Bespoke switchIsOpenForm={switchIsOpenForm} />
        </div>
        <div className={classes.Section}>
          <OurPartners switchIsOpenForm={switchIsOpenForm} />
        </div>
        <div className={classes.Section}>
          <OurHighlights />
        </div>
        <div className={classes.Section}>
          <LetsTalk switchIsOpenForm={switchIsOpenForm} />
        </div>
      </main>
      {platform === 'desktop' ? <ContactForm/> : null}
    </React.Fragment>
  )
}

export default App
