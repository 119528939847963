import React from 'react'
import { useSelector } from 'react-redux'
import classes from './AboutUs.module.scss'

import aboutUs from '../img/about-us.webp'

const AboutUs = () => {
  const locale = useSelector(state => state.locale.locale)

  return (
    <article data-nav-id='aboutUs' className={classes.AboutUsPage}>
      <div className={classes.Content}>
        <div className={classes.Plus}>+</div>
        <h1 className={classes.Title}>{locale.aboutUs}</h1>
        <div className={classes.Paragraph}>{locale.aboutUsText1}</div>
        <div className={classes.Paragraph}>{locale.aboutUsText2}</div>
        <div className={classes.StatisticBlock}>
          <div className={classes.StatisticItem}>
            <div className={classes.StatisticTitle}>8</div>
            <div className={classes.StatisticText}>{locale.aboutUsText3}</div>
          </div>
          <div className={classes.StatisticItem}>
            <div className={classes.StatisticTitle}>60+</div>
            <div className={classes.StatisticText}>{locale.aboutUsText4}</div>
          </div>
          <div className={classes.StatisticItem}>
            <div className={classes.StatisticTitle}>480,000</div>
            <div className={classes.StatisticText}>{locale.aboutUsText5}</div>
          </div>
        </div>
      </div>
      <img className={classes.AboutUsImage} src={aboutUs} alt='AboutUsImage' loading="lazy" />
    </article>
  )
}

export default AboutUs
