const actionTypes = {
  GET_TOKEN: 'demo/GET_TOKEN',
  SET_ACTIVE_GAME: 'demo/SET_ACTIVE_GAME',
}

const platform = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? 'mobile' : 'desktop';

const getToken = () => async (dispatch, getState, extra) => {
  const { api } = extra;
  const response = await api.demo.getToken();

  if (response.success) {
    dispatch({ type: actionTypes.GET_TOKEN, payload: response.data });
  }
}

const startActiveGame = () => async (dispatch, getState, extra) => {
  const { api } = extra;
  const response = await api.demo.getToken();

  if (response.success) {
    const activeGame = getState().demo.activeGame;
    const { lang } = getState().locale;

    if (platform === 'mobile' && activeGame) {
      location.href = `https://${activeGame}.games.fiable.games/?token=${response.data.session_token}&lang=${lang}&homeUrl=https://fiablegames.com`
    }
  }
}

const setActiveGame = game => dispatch => dispatch({ type: actionTypes.SET_ACTIVE_GAME, payload: game })

export {
  actionTypes,
  getToken,
  startActiveGame,
  setActiveGame
}