import React from 'react'
import block from 'bem-cn'
import SVGInline from 'react-svg-inline'

import { NotifyModel } from 'services/models/NotifyModel'
import CrossSVG from '../img/cross.svg'

import { notificationImg } from '../type'

import './Notification.scss'

class Notification extends React.PureComponent {
  constructor(props) {
    super(props)
    this.notifyRef = React.createRef()
    this.state = {
      isDeleting: false,
      isDeleted: false,
    }
  }

  componentDidMount() {
    const { needClose } = this.props
    const touchListener = new NotifyModel(() => this._hideNotify(), this.notifyRef.current)
    touchListener.init()
    if (needClose) {
      setTimeout(this._hideNotify, 7000)
    }
  }

  render() {
    const b = block('notification')
    const { text, type } = this.props
    return (
      <div className={b({ deleting: this.state.isDeleting }, { type })} ref={this.notifyRef}>
        <img src={notificationImg[type].img} className={b(`img`).toString()} alt='notification' />
        <span className={b('text')}>{text}</span>
        <img className={b('close').toString()} src={CrossSVG} onClick={() => this._hideNotify()} alt='close' />
      </div>
    )
  }

  _hideNotify = () => {
    const { deleteNotify, id } = this.props
    if (!this.state.isDeleted) {
      this.setState({ isDeleting: true }, () =>
        setTimeout(() => {
          this.setState({ isDeleted: true })
          deleteNotify(id)
        }, 700),
      )
    }
  }
}

export default Notification
