import React from 'react'
import { useSelector } from 'react-redux'
import classes from './OurPartners.module.scss'

import { partners } from './data';

const OurPartners = ({ switchIsOpenForm }) => {
  const locale = useSelector(state => state.locale.locale)

  const partnersList = partners.map(t => <section className={classes.Game} key={t.name}>
    <div className={classes.GameTop}>
      <img className={classes.GameLogo} src={t.icon} alt='PartnerLogo' loading="lazy" />
    </div>
    <div className={classes.GameBottom}>
      <div className={classes.GameName}>{t.name}</div>
    </div>
  </section>)

  return (
    <article data-nav-id='ourPartners' className={classes.OurPartners}>
      <div className={classes.Plus}>+</div>
      <div className={classes.ContentTop}>
        <h1 className={classes.Title}>{locale.ourPartners}</h1>
        <div className={classes.Description}>{locale.ourPartnersDescription}</div>
      </div>
      <div className={classes.Games}>{partnersList}</div>
    </article>
  )
}

export default OurPartners;
