import initialState from './initial';
import { actionTypes } from './actions';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TOKEN: {
      return { ...state, token: action.payload.session_token }
    }

    case actionTypes.SET_ACTIVE_GAME: return {
      ...state,
      activeGame: action.payload
    }

    default: return state
  }
}

export { reducer }