const menuItems = [
  {
    textIdent: 'main',
    link: 1,
    id: 'main',
  },
  {
    textIdent: 'aboutUs',
    link: 2,
    id: 'aboutUs',
  },
  {
    textIdent: "letsTryMenu",
    link: 3,
    id: 'LetsTry',
  },
  {
    textIdent: 'ourGames',
    link: 4,
    id: 'ourGames',
  },
  {
    textIdent: 'newGame',
    link: 5,
    id: 'newGame',
  },
  {
    textIdent: 'bespokeGaming',
    link: 6,
    id: 'bespokeGaming',
  },
  {
    textIdent: 'ourPartners',
    link: 7,
    id: 'ourPartners',
  },
  {
    textIdent: 'highlights',
    link: 8,
    id: 'highlights',
  },
  {
    textIdent: 'contuctUs',
    link: 9,
    id: 'contuctUs',
  },
]

const socialItems = [
  {
    text: 'Facebook',
    link: '#',
  },
  {
    text: 'Instagram',
    link: '#',
  },
  {
    text: 'Telegram',
    link: 'https://t.me/Casino_develop',
    
  },
]

export { menuItems, socialItems }
