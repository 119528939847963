import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import classes from './NewGame.module.scss'

import Button from 'components/Button'

import star from '../img/star.svg'
import plinko from '../img/plinko.svg'

const NewGame = ({ switchIsOpenForm }) => {
  const [corner, setCorner] = useState(0)
  const checkCoordination = useCallback(e => {
    setCorner(e.clientY / 5)
  }, [])
  const locale = useSelector(state => state.locale.locale)

  return (
    <article data-nav-id='newGame' className={classes.NewGamePage} onMouseMove={checkCoordination}>
      <section className={classes.Game}>
        <section className={classes.GameWrapper}>
          <div className={classes.GameTop}>
            <img className={classes.GameLogo} style={{transform: `rotate(${corner}deg`}} src={star} corner={corner} alt='star' loading="lazy" />
            <img className={classes.GameImg} src={plinko} alt='newGame' loading="lazy" />
          </div>
          <div className={classes.GameBottom}>
            <h2 className={classes.GameName} >Plinko</h2>
            <h2 className={classes.GameDesc}>{locale.newGameLudoText}</h2>
            <div className={classes.ButtonContainer}>
              <Button
                callBack={switchIsOpenForm}
                withArrow
                text={locale.moreDetails}
                size="container"
                fontSize="small"
              />
            </div>
          </div>
        </section>
      </section>
      <div className={classes.TextContainer}>
        <div className={classes.Plus}>+</div>
        <h1 className={classes.Title}>{locale.newGameFirstTitle}</h1>
        <h1 className={classes.Title}>{locale.newGameSecondTitle}</h1>
        <div className={classes.Description}>{locale.newGameDesc}</div>
      </div>
    </article>
  )
}

export default NewGame
