import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';

import { actions } from 'features/Demo/logic';
import full from './img/full2.svg';
import out from './img/out2.svg';

import classes from './LetsTry.module.scss';

const platform = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? 'mobile' : 'desktop';

const LetsTry = () => {
    const dispatch = useDispatch();
    const locale = useSelector(state => state.locale.locale);
    const { token } = useSelector(state => state.demo);
    const { lang } = useSelector(state => state.locale);
    const [isFull, setIsFull] = useState(false);

    const onFullClick = useCallback(() => {
        setIsFull(prev => !prev);
    }, [])

    useEffect(() => {
        dispatch(actions.getToken());
    }, []);

    return (
        <div data-nav-id='LetsTry' className={classes.LetsTry}>
            <div className={classes.Plus}>+</div>
            <div className={classes.ContentTop}>
                <h1 className={classes.Title}>{locale.letsTry}</h1>
                <div className={classes.Description}>{locale.ourGameFirecrashText}</div>
            </div>
            <div className={cn(classes.DemoContainer, { [classes.DemoContainerFull]: isFull })}>
                <div className={classes.FrameContainer}>
                    {platform === 'mobile' && (
                        <div className={isFull ? classes.FullScreenIconContainer: classes.NotFullScreenIconContainer}>
                            <img className={classes.Icon} src={isFull ? out : full} onClick={onFullClick} />
                        </div>
                    )}
                    {token ?
                    <iframe className={cn(classes.DemoFrame, { [classes.DemoFrameFull]: isFull })} title="Firecrash" src={`https://firecrash.games.fiable.games/?token=${token}&lang=${lang}&homeUrl=https://fiablegames.com`} alt='Firecrash' />
                    : null}
                </div>
            </div>
        </div>
    )
}

export default LetsTry;