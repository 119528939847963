import React, { useCallback, useState, useMemo } from 'react'
import cn from 'classnames'
import { useSelector, useDispatch } from 'react-redux'

import Input from 'components/Input'
import Button from 'components/Button'

import { actions } from './logic'

import close from './img/close.svg'

import classes from './Form.module.scss'

const Form = ({ isOpen, switchIsOpenForm }) => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const { locale } = useSelector(state => state.locale)

  const stopFunc = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const sendEmail = useCallback(() => dispatch(actions.sendEmail(email, switchIsOpenForm)), [
    email,
    dispatch,
    switchIsOpenForm,
  ])

  const isValidate = useMemo(() => {
    // eslint-disable-next-line
    return email.length !== 0;
  }, [email])

  return (
    <div className={cn(classes.ModalWrapper, {[classes.ModalWrapperIsOpen]: isOpen})} onClick={switchIsOpenForm}>
      <article className={cn(classes.FormBlock, {[classes.FormBlockIsOpen]: isOpen})} onClick={stopFunc}>
        <img className={classes.Close} src={close} onClick={switchIsOpenForm} alt='close'/>
        <h1 className={classes.Title}>{locale.contact}</h1>
        {/* <AdditionalText>{locale.callUs}</AdditionalText> */}
        <span className={classes.AdditionalText}>{locale.contactText}</span>
        <form className={classes.FormElement}>
          <label className={classes.Label} key="email">
            <span className={classes.LabelText}>Skype/Telegram</span>
            <Input
              id="email"
              type="text"
              name="email"
              value={email}
              isRequired
              placeholder="@Casino_develop"
              callBack={value => setEmail(value)}
            />
          </label>
          <div className={classes.ButtonContainer}>
            <Button
              type="submit"
              callBack={sendEmail}
              withArrow
              isDisabled={!isValidate}
              text={locale.cooperate}
              size="container"
              fontSize="large"
            />
          </div>
        </form>
      </article>
    </div>
  )
}

export default Form
