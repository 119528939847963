import hilo from '../../img/hilo.svg'
import firecrash from '../../img/firecrash.svg'
import spin2win from '../../img/spin2win.svg'
import penalty from '../../img/penalty.svg'
import glassBridge from '../../img/glassBridge.svg'
import squid from '../../img/squid.svg'
import cookies from '../../img/cookies.svg'
import dino from '../../img/dino.svg'
import keno from '../../img/keno.svg'
import plinko from '../../img/plinko.svg'
import coinflip from '../../img/coinflip.svg'
import dice from '../../img/dice.svg'

const games = [
  {
    gameName: 'firecrash',
    icon: firecrash,
    name: 'Firecrash',
    textIdent: 'ourGameFirecrashText',
  },
  {
    gameName: 'spin2win',
    icon: spin2win,
    name: 'Spin2Win VIP',
    textIdent: 'ourGameSpin2WinText',
  },
  {
    gameName: 'cookies',
    icon: cookies,
    name: 'Cookies',
    textIdent: 'ourGameCookiesText',
  },
  {
    gameName: 'bridge',
    icon: glassBridge,
    name: 'Glass Bridge',
    textIdent: 'ourGameGlassBridgeText',
  },
  {
    gameName: 'hilo',
    icon: hilo,
    name: 'HiLo',
    textIdent: 'ourGameHiloText',
  },
  {
    gameName: 'plinko',
    icon: plinko,
    name: 'Plinko',
    textIdent: 'ourGamePlinkoText',
  },
  {
    gameName: 'keno',
    icon: keno,
    name: 'Keno',
    textIdent: 'ourGameKenoText',
  },
  {
    gameName: 'coinflip',
    icon: coinflip,
    name: 'Coinflip',
    textIdent: 'ourGameCoinflipText',
  },
  // {
  //   gameName: 'dice',
  //   icon: dice,
  //   name: 'Dice',
  //   textIdent: 'ourGameDiceText',
  // },
  {
    gameName: 'dino',
    icon: dino,
    name: 'Dino',
    textIdent: 'ourGameDinoText',
  },
  {
    gameName: 'penalty',
    icon: penalty,
    name: 'Penalty Vip',
    textIdent: 'ourGamePenaltyText',
  },
  {
    gameName: 'squid',
    icon: squid,
    name: 'Squid Crash',
    textIdent: 'ourGameSquidText',
  },
]

export { games };