import dayjs from 'dayjs';
import { languages } from 'services/locale';

import 'dayjs/locale/en';
import 'dayjs/locale/fr';

const actionTypes = {
  CHANGE_LANG: 'locale/CHANGE_LANG',
};

function changeLang(lang = 'fr') {
  return async dispatch => {
    const { locale } = await import(`services/locale/${lang}/index`);
    document.querySelector('html').lang = languages[lang];
    dayjs.locale(languages[lang]);
    dispatch({ type: actionTypes.CHANGE_LANG, payload: { locale, lang } });
  };
}

export {
  actionTypes,
  changeLang,
};