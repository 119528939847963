import { compose, applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import persistState from 'redux-localstorage'

import { reducer as localeReducer } from './features/locale'
import { reducer as notifyReducer } from './features/Notify'
import { reducer as demoReducer } from './features/Demo'

function configureStore(extra) {
  const middlewares = [thunk.withExtraArgument({ ...extra })]

  const reducer = createReducer()

  const store = createStore(
    reducer,
    compose(
      applyMiddleware(...middlewares),
      // persistState([]),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }) : arg => arg,
    ),
  )

  return store
}

function createReducer() {
  return combineReducers({
    locale: localeReducer,
    notify: notifyReducer,
    demo: demoReducer,
  })
}

export { createReducer }
export default configureStore
