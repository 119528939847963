import React, { useState } from 'react';
import block from 'bem-cn';

import icon from './img/form.svg';

import './ContactForm.scss';

const b = block('contact-form');

const ContactForm = () => {
    return <div className={b()}>
        <a href={'https://docs.google.com/forms/d/1sXGKE4SwUBRMiRzBkNRUET2zwn3wVF-aQiC4Tt-H8nM/edit'} className={b('link')}>
            <img
                className={b('item')}
                src={icon}
                alt='GoogleForm'
            />
        </a>
    </div>
};

export default ContactForm;