import React, { useCallback } from 'react'
import cn from 'classnames'
import { useSelector, useDispatch } from 'react-redux'

import { languages } from 'services/locale'

import { actions as localeActions } from 'features/locale'

import { menuItems, socialItems } from './data'

import close from './img/close.svg'
import arrow from './img/arrow.svg'
import Contacts from 'components/Contacts';
import Button from 'components/Button';

import classes from './SideMenu.module.scss'
import { actions } from '../../features/locale'

const SideMenu = ({ isOpen, switchIsOpenSideMenu, switchIsOpenForm }) => {
  const dispatch = useDispatch()
  const { locale, lang } = useSelector(state => state.locale)

  const changeLang = useCallback(newLang => dispatch(localeActions.changeLang(newLang)), [dispatch]);

  const handleNavigation = useCallback((id) => {
    const el = document.querySelector(`[data-nav-id="${id}"]`);

    if (!el) {
      return;
    }
    
    switchIsOpenSideMenu();
    el.scrollIntoView({ behavior: 'smooth' });
  }, [switchIsOpenSideMenu]);

  const menuItemsList = menuItems.map(t => (
    <div className={classes.MenuItem} key={t.link} onClick={() => handleNavigation(t.id)}>
      {locale.sideMenuItems[t.textIdent]}
    </div>
  ))

  const languagesList = Object.entries(languages).map(t => (
    <div className={cn(classes.LangItem, { [classes.LangItemIsActive]: lang === t[0] })} key={t[0]} onClick={() => changeLang(t[0])}>
      {t[1]}
    </div>
  ))

  const socialsList = socialItems.map(t => (
    <a className={classes.SocialItem} key={t.text} href={t.link} target="_blank">
      {t.text}
      <img className={classes.SocialArrow} src={arrow} alt='arrow' />
    </a>
  ))

  return (
    <article className={cn(classes.SideMenuBlock, { [classes.SideMenuBlockIsOpen]: isOpen })}>
      <div className={classes.SideMenuWrapper}>
        <nav className={classes.Menu}>{menuItemsList}</nav>
        <div className={classes.AdditionalBlock}>
          <img className={classes.Close} src={close} onClick={switchIsOpenSideMenu} alt='arrow' />
          <div className={classes.LanguagesBlock}>{languagesList}</div>
          < article className={classes.Socials} >
            <Contacts />
            <div className={classes.ButtonContainer}>
              <Button callBack={switchIsOpenForm} withArrow text={locale.cooperate} size="container" fontSize="normal" />
            </div>
          </ article>
        </div>
      </div>
    </article>
  )
}

export default SideMenu
