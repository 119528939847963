import React from 'react'
import { useSelector } from 'react-redux'
import classes from './Bespoke.module.scss'

import Contacts from 'components/Contacts'

import main from '../img/bespoke-main.svg'
import additional from '../img/bespoke-additional.svg'

const Bespoke = ({ switchIsOpenForm }) => {
  const locale = useSelector(state => state.locale.locale)

  return (
    <article data-nav-id='bespokeGaming' className={classes.BespokePage}>
      <img className={classes.MainImage} src={main} alt="main" loading="lazy" />
      <div className={classes.ContentBlock}>
        <div className={classes.TextBlock}>
          <div className={classes.TitleContainer}>
            <div className={classes.Plus}>+</div>
            <h1 className={classes.Title}>{locale.bespokeFirstTitle}</h1>
            <h1 className={classes.Title}>{locale.bespokeSecondTitle}</h1>
          </div>
          <div className={classes.SubTitle}>{locale.bespokeSubTitle}</div>
          <div className={classes.Description}>{locale.bespokeDesk}</div>
        </div>
        <div className={classes.ButtonBlock}>
          <img className={classes.AddImage} src={additional} alt="additional" loading="lazy" />
          <div className={classes.ButtonText}>{locale.bespokeByttonText}</div>
          <Contacts />
        </div>
      </div>
    </article>
  )
}

export default Bespoke
