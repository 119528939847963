import React, { useState } from 'react';
import block from 'bem-cn'

import telegram from './img/telegram.svg';
import telegramOrange from './img/telegram_orange_white.svg';
import whatsapp from './img/whatsapp1.svg';
import whatsappWhite from './img/whatsapp1_white.svg';
import facebook from './img/facebook.svg';
import facebookOrange from './img/facebook_orange.svg';
import link from './img/linkedin.svg';
import linkOrange from './img/linkedin_orange.svg';
import inst from './img/instagram.svg';
import instOrange from './img/instagram_orange.svg';

import './Contacts.scss';

const b = block('contacts')

const Contacts = () => {
    const [onTelegramMove, setOnTelegramMove] = useState(false);
    const [onWhatsupMove, setOnWhatsupMove] = useState(false);
    const [onFacebookMove, setOnFacebookMove] = useState(false);
    const [onLinkMove, setOnLinkMove] = useState(false);
    const [onInstMove, setOnInstMove] = useState(false);
    return (
        <div className={b()}>
            <a href={'https://t.me/fiablegames'} className={b('link')}>
                <img
                    className={b('item', { isHover: onTelegramMove })}
                    src={onTelegramMove ? telegram : telegramOrange}
                    alt='Telegram'
                    onMouseOver={() => setOnTelegramMove(true)}
                    onMouseOut={() => setOnTelegramMove(false)}
                />
            </a>
            <a href={'https://wa.me/37494418811'} className={b('link')}>
                <img
                    className={b('item', { isHover: onWhatsupMove })}
                    src={onWhatsupMove ? whatsapp : whatsappWhite}
                    alt='WhatsApp'
                    onMouseOver={() => setOnWhatsupMove(true)}
                    onMouseOut={() => setOnWhatsupMove(false)}
                />
            </a>
            <a href={'https://www.facebook.com/fiablegamesllc'} className={b('link')}>
                <img
                    className={b('item', { isHover: onFacebookMove })}
                    src={onFacebookMove ? facebook : facebookOrange}
                    alt='Facebook'
                    onMouseOver={() => setOnFacebookMove(true)}
                    onMouseOut={() => setOnFacebookMove(false)}
                />
            </a>
            <a href={'https://www.linkedin.com/company/fiablegames/'} className={b('link')}>
                <img
                    className={b('item', { isHover: onLinkMove })}
                    src={onLinkMove ? link : linkOrange}
                    alt='LinkedIn'
                    onMouseOver={() => setOnLinkMove(true)}
                    onMouseOut={() => setOnLinkMove(false)}
                />
            </a>
            <a href={'https://www.instagram.com/fiable_games/'} className={b('link')}>
                <img
                    className={b('item', { isHover: onInstMove })}
                    src={onInstMove ? inst : instOrange}
                    alt='Instagram'
                    onMouseOver={() => setOnInstMove(true)}
                    onMouseOut={() => setOnInstMove(false)}
                />
            </a>
        </div>
    );
};

export default Contacts;