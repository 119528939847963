import FormApi from './FormApi'
import DemoApi from './DemoApi'

class Api {
  constructor() {
    this.form = new FormApi()
    this.demo = new DemoApi()
  }
}

export default Api
