import React from 'react'
import { useSelector } from 'react-redux'
import classes from './LetsTalk.module.scss'

import Contacts from 'components/Contacts'

const LetsTalk = ({ switchIsOpenForm }) => {
  const locale = useSelector(state => state.locale.locale)

  return (
    <article data-nav-id='contuctUs' className={classes.LetsTalkPage}>
      <div className={classes.Content}>
        {locale.letsTalkText1}
        <Contacts />
      </div>
      <div className={classes.LetsTalkText}>{locale.letsTalk}</div>
    </article>
  )
}

export default LetsTalk
