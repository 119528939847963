import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classes from './OurGames.module.scss'

import Button from 'components/Button'
import { actions as demoActions } from 'features/Demo';

import { games } from './data';

const OurGames = ({ switchIsOpenForm }) => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale)
  const setActiveGame = useCallback(game => dispatch(demoActions.setActiveGame(game)), [dispatch])

  const gamesList = games.map(t => <section className={classes.Game} key={t.name}>
    <div className={classes.GameTop}>
      <img className={classes.GameLogo} src={t.icon} alt='GameLogo' loading="lazy" />
    </div>
    <div className={classes.GameBottom}>
      <div className={classes.GameName}>{t.name}</div>
      <div className={classes.GameDesc}>{locale[t.textIdent]}</div>
      <div className={classes.ButtonContainer}>
        <Button
          callBack={switchIsOpenForm}
          withArrow
          text={locale.moreDetails}
          size="half"
          fontSize="small"
        />
        <Button
          callBack={() => setActiveGame(t.gameName)}
          withArrow
          text="DEMO"
          size="half"
          fontSize="small"
          color="orange"
        />
      </div>
    </div>
  </section>)

  return (
    <article data-nav-id='ourGames' className={classes.OurGamesPage}>
      <div className={classes.Plus}>+</div>
      <div className={classes.ContentTop}>
        <h1 className={classes.Title}>{locale.ourGames}</h1>
        <div className={classes.Description}>{locale.ourGamesDescription}</div>
      </div>
      <div className={classes.Games}>{gamesList}</div>
    </article>
  )
}

export default OurGames
