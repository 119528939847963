import { actions as notifyActions } from 'features/Notify'

let isProcess = false;

const sendEmail = (email, callback) => async (dispatch, getState, extra) => {
  const { api } = extra
  const { locale } = getState().locale

  if (!isProcess) {
    isProcess = true;

    const response = await api.form.sendEmail(email)

    if (response.success) {
      dispatch(notifyActions.addNotify(locale.sendEmailSuccess(email), 'success'))
      if (callback) {
        callback()
      }
    } else {
      dispatch(notifyActions.addNotify(locale.sendEmailFail, 'error'))
    }
    
    isProcess = false;
  } 

}

export { sendEmail }
