import React from 'react'
import { useSelector } from 'react-redux'
import classes from './Best.module.scss'

import Contacts from 'components/Contacts'

import logo from '../img/logo.svg'
import burger from '../img/burger.svg'

const Best = ({ switchIsOpenSideMenu, switchIsOpenForm }) => {
  const locale = useSelector(state => state.locale.locale)

  return (
    <article data-nav-id='main' className={classes.BestPage}>
      <div className={classes.TopContainer}>
        <img className={classes.Logo} src={logo} alt='logo' loading="lazy" />
        <img className={classes.Burger} src={burger} onClick={switchIsOpenSideMenu} alt='burger' loading="lazy" />
      </div>
      <div className={classes.TopTextBlock}>{locale.bestb2b}</div>
      <div className={classes.BottomTextBlock}>
        <div className={classes.BottomSmallText}>{locale.betsText}</div>
        <div className={classes.BottomBigText}>{locale.casinoGames}</div>
      </div>
      <Contacts />
    </article>
  )
}

export default Best
